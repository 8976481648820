export const resources = {
  fr: {
    translation: {
      home: "Accueil",
      service: "Service",
      work: "Travail",
      skills: "Compétences",
      team: "Équipe",
      blog: "Blog",
      testimonial: "temoignages",
      contact: "Contact",

      //hero section
      "Empowering Success Through Technology":
        "Favoriser le succès grâce à la technologie",
      "Transforming lives and scaling businesses with technology":
        "Transformer des vies et développer des entreprises grâce à la technologie",
      "Get In Touch": "Contactez-nous",
      " Visit AgriHyphen AI our ongoing project":
        "Visitez AgriHyphen AI, notre projet en cours.",
      "Our services": "Nos services",
      // header pages

      "The Showcase Study": "L'étude de vitrine",
      "Teamwork is the Key": "Le travail d'équipe est la clé",
      "The Team": "L'équipe",
      "Skills & Experiences": "Compétences & expériences",
      "Hello, Welcome to": "Bonjour, bienvenue à",
      "The hyphen Blog": "Le blog de Hyphen Tech",
      "what we are Good at": "ce que nous faisons bien",
      "Our Portfolio": "Notre Portfolio",

      // what we do better

      "Web Development": "Développement Web",
      "We create stunning websites that showcase your activities to the world. Our websites are easy to use and look great on any device":
        "Nous créons des sites web époustouflants qui mettent en valeur vos activités dans le monde entier. Nos sites sont faciles à utiliser et ont une apparence optimale sur tout appareil.",

      "App Development": "Développement d'applications",
      "We build apps for your business that work smoothly on both iPhones and Androids. Our apps have cool features to keep your users engaged":
        "Nous concevons des applications pour votre entreprise qui fonctionnent parfaitement sur iPhones et Androids. Nos applications possèdent des fonctionnalités attrayantes pour fidéliser vos utilisateurs.",

      "Microservices Architecture": "Architecture de microservices",
      "We help turn your business ideas into reality using smart and flexible technology. This means your business can grow and change easily":
        "Nous transformons vos idées d'entreprise en réalité en utilisant des technologies intelligentes et flexibles, permettant ainsi à votre entreprise de croître et de s'adapter facilement.",

      "Deployment Services": "Services de déploiement",
      "We make sure your website or app gets up and running smoothly. After it's launched, we keep an eye on it to make sure it works well all the time":
        "Nous veillons à ce que votre site web ou votre application soit opérationnel sans problème. Après son lancement, nous le surveillons pour assurer un fonctionnement optimal en tout temps.",

      "UI, UX & Graphic Design": "UI, UX et Design Graphique",
      "We create cool and unique visuals for your brand. Whether it's for your website or app, our designs will make you remarquable and stand out to the rest":
        "Nous créons des visuels uniques et attrayants pour votre marque. Que ce soit pour votre site web ou votre application, nos conceptions vous rendront remarquable et vous démarqueront des autres.",

      "Social Media Management and Consultancy":
        "Gestion des médias sociaux et conseils",
      "We help you build a strong online presence. We guide you on what to post and how to connect with your audience on social media.":
        "Nous vous aidons à bâtir une présence en ligne solide. Nous vous conseillons sur ce qu'il faut publier et comment interagir avec votre audience sur les réseaux sociaux.",

      "Web maitenancy": "Maintenance web",
      "We specialize in web maintenance to keep your online presence strong. Our team goes beyond routine upkeep, adding new features and optimizing performance for lasting success":
        "Nous sommes spécialisés dans la maintenance web pour maintenir votre présence en ligne forte. Notre équipe va au-delà de l'entretien de routine, en ajoutant de nouvelles fonctionnalités et en optimisant les performances pour un succès durable.",
      "Education Initiatives": "Initiatives éducatives",
      "We love sharing knowledge! At Hyphen Tech Education, a non-profit initiative, We teach university students about computers and help high school students to get ready for their future studies and jobs.":
        "Nous aimons partager nos connaissances ! À Hyphen Tech Education, une initiative à but non lucratif, nous enseignons aux étudiants universitaires l'informatique et aidons les lycéens à se préparer à leurs futures études et emplois.",

      "Live streaming services": "Services de diffusion en direct",
      "We provide live streaming services for events and conferences to ensure that the events are accessible to a wider audience and to provide a platform for speakers to share their knowledge and experiences.":
        "Nous fournissons des services de diffusion en direct pour les événements et les conférences pour garantir que les événements soient accessibles à un public plus large et pour fournir une plateforme pour les orateurs partager leurs connaissances et leurs expériences.",

      //Our portofolio

      "AgriHyphen AI: Empowering Farmers with AI Technology":
        "AgriHyphen AI : Autonomiser les agriculteurs grâce à la technologie de l'IA",
      "AgriHyphen AI is an innovative platform designed to empower farmers and agricultural cooperatives through the use of artificial intelligence. Our project focuses on leveraging computer vision technology to detect diseases in solanaceae crops, such as potatoes and tomatoes, with an impressive accuracy of 94%. By providing farmers with timely and accurate information about crop health, we aim to reduce waste and enhance productivity.":
        "AgriHyphen AI est une plateforme innovante conçue pour autonomiser les agriculteurs et les coopératives agricoles grâce à l'utilisation de l'intelligence artificielle. Notre projet se concentre sur l'exploitation de la technologie de vision par ordinateur pour détecter les maladies dans les cultures de solanacées, telles que les pommes de terre et les tomates, avec une précision impressionnante de 94 %. En fournissant aux agriculteurs des informations précises et en temps opportun sur la santé des cultures, nous visons à réduire le gaspillage et à améliorer la productivité.",
      "Hyphen tech blog, the blog for university students":
        "Blog Hyphen Tech, le blog pour les étudiants universitaires",
      "Provide Valuable And Insightful Content That Educates, Inspires, And Empowers Readers In Various Areas Of Interest":
        "Fournir un contenu précieux et perspicace qui éduque, inspire et donne aux lecteurs les moyens d'agir dans divers domaines d'intérêt.",

      "Collabaration of Hyphen tech and American corner":
        "Collaboration entre Hyphen Tech et American Corner",
      "At Hyphen Tech, our collaboration with American Corner Ngozi began with a shared vision to advance technology and digital literacy in our community. This partnership has allowed us to create valuable content that educates and inspires":
        "Chez Hyphen Tech, notre collaboration avec American Corner Ngozi a commencé avec une vision commune pour promouvoir la technologie et l'alphabétisation numérique dans notre communauté. Ce partenariat nous a permis de créer un contenu précieux qui éduque et inspire.",

      "Bismos impremerie softwares": "Logiciels Bismos Imprimerie",
      "we developed a comprehensive software management system for Bismos Imprimerie, revolutionizing their business operations. Our solution includes robust features for employee management, stock management, sales tracking, and invoicing.":
        "Nous avons développé un système de gestion logicielle complet pour Bismos Imprimerie, révolutionnant leurs opérations commerciales. Notre solution comprend des fonctionnalités robustes pour la gestion des employés, la gestion des stocks, le suivi des ventes et la facturation.",

      "Bismos print website": "Site web Bismos Print",
      "We developed a  website for Bismos Print, designed to showcase their activities and services to a wider audience. This user-friendly platform highlights their extensive range of printing solutions, portfolio of past projects, and detailed service offerings.":
        "Nous avons développé un site web pour Bismos Print, conçu pour mettre en valeur leurs activités et services auprès d'un public plus large. Cette plateforme conviviale met en avant leur large gamme de solutions d'impression, leur portefeuille de projets passés, et leurs offres de services détaillées.",

      "Hyphen Tech Education": "Hyphen Tech Education",
      "We love sharing knowledge! At Hyphen Tech Education, a non-profit initiative, We teach university students about computers and help high school students to get ready for their future studies and jobs.":
        "Nous aimons partager nos connaissances ! À Hyphen Tech Education, une initiative à but non lucratif, nous enseignons aux étudiants universitaires l'informatique et aidons les lycéens à se préparer à leurs futures études et emplois.",

      // Testimonials
      "Vice-Chancellor of Ngozi University": "Recteur de l'université de Ngozi",
      "Hyphen Tech stands out for its commitment to providing concrete solutions to the daily problems of our community. Their innovative and practical approach shows real potential to transform our society.They have also created a blog for the students of Ngozi University, a crucial initiative that facilitates the exchange of ideas and opportunities. This work clearly demonstrates the credibility and vision of Hyphen Tech as one of the leaders in technological innovation in Burundi.":
        "Hyphen Tech se distingue par son engagement à apporter des solutions concrètes aux problèmes quotidiens de notre communauté. Leur approche innovante et pratique montre un réel potentiel pour transformer notre société. Ils ont également créé un blog pour les étudiants de l'Université de Ngozi, une initiative cruciale qui facilite l'échange d'idées et d'opportunités. Ce travail démontre clairement la crédibilité et la vision de Hyphen Tech en tant que l'un des leaders dans l'innovation technologique au Burundi.",

      "CEO and founder of Resolution Connect":
        "PDG et fondateur de Resolution Connect",
      "Hyphen Tech has been instrumental in our startup's success. Their expertise in email marketing, event digital marketing, and logo crafting significantly boosted our brand's visibility. Their creativity and professionalism are unmatched. We highly recommend Hyphen Tech's exceptional services.":
        "Hyphen Tech a été essentiel au succès de notre startup. Leur expertise en marketing par e-mail, en marketing digital pour les événements et en conception de logos a considérablement accru la visibilité de notre marque. Leur créativité et leur professionnalisme sont inégalés. Nous recommandons vivement les services exceptionnels de Hyphen Tech.",

      "Coordinator of American corner Ngozi":
        "Coordinateur de l'American Corner Ngozi",
      "Partnering with Hyphen Tech has revolutionized our digital marketing, live broadcasts of events, and space design at the American Corner Ngozi. Their dedication to promoting digital literacy among the underprivileged has made a significant impact. We highly recommend Hyphen Tech for their outstanding services.":
        "Le partenariat avec Hyphen Tech a révolutionné notre marketing digital, nos diffusions en direct d'événements et la conception de notre espace à l'American Corner Ngozi. Leur dévouement à promouvoir l'alphabétisation numérique parmi les défavorisés a eu un impact significatif. Nous recommandons vivement Hyphen Tech pour leurs services exceptionnels.",
      "Trusted by": "Approuvé par",

      // Team members

      "Co-founder & CEO of Hyphen Tech": "Co-fondateur & PDG de Hyphen Tech",
      "Full Stack Developer": "Développeur Full Stack",
      "Meet Elie Bubuya, the driving force behind Hyphen Tech and a visionary leader. As the founder of Hyphen Tech, Elie exemplifies the spirit of a tech entrepreneur and a highly skilled full stack programmer. With a passion for innovation and a keen eye for emerging technologies, Elie brings a wealth of expertise to our team. His leadership sets the tone for our commitment to excellence and forward-thinking solutions, making him an integral part of our journey toward technological empowerment.":
        "Rencontrez Elie Bubuya, la force motrice derrière Hyphen Tech et un leader visionnaire. En tant que fondateur de Hyphen Tech, Elie incarne l'esprit d'un entrepreneur technologique et un programmeur full stack très compétent. Avec une passion pour l'innovation et un œil averti pour les technologies émergentes, Elie apporte une richesse d'expertise à notre équipe. Son leadership donne le ton à notre engagement envers l'excellence et les solutions avant-gardistes, faisant de lui une partie intégrante de notre parcours vers l'autonomisation technologique.",

      "Co-founder & COO of Hyphen Tech":
        "Co-fondateur & Directeur des opérations de Hyphen Tech",
      "Full Stack Developer": "Développeur Full Stack",
      "Meet Dieudonne Abanabimana, the co-founder of Hyphen Tech. Passionate about programming, Dieudonne is a skilled full-stack developer who plays a key role in shaping our technological solutions. With a commitment to excellence, he brings invaluable expertise to our team, contributing to our mission of innovative and impactful solutions in the ever-evolving tech landscape":
        "Rencontrez Dieudonné Abanabimana, co-fondateur de Hyphen Tech. Passionné de programmation, Dieudonné est un développeur full-stack expérimenté qui joue un rôle clé dans la conception de nos solutions technologiques. Avec un engagement envers l'excellence, il apporte une expertise inestimable à notre équipe, contribuant à notre mission de solutions innovantes et impactantes dans le paysage technologique en constante évolution.",

      // Skills

      "Frontend Developer": "Développeur Frontend",
      company: "Tech Solutions",
      "Developed user interfaces using React and Redux":
        "Développement d'interfaces utilisateur réactives en utilisant React.",

      "UI/UX Designer": "Consultant en conception UX/UI",
      company: "Design Experts",
      "Designed user-friendly interfaces and improved user experience":
        "Conseils en conception et développement d'interfaces utilisateur centrées sur l'utilisateur.",

      "Full Stack Developer": "Ingénieur Full Stack",
      company: "InnoTech",
      "Worked on both frontend and backend technologies to build web applications.":
        "Développement d'applications web à grande échelle utilisant Node.js et React.",

      name: "Lead Développeur",
      company: "Startup Innovante",
      desc: "Direction d'une équipe de développeurs pour la création de solutions innovantes.",

      "Software Engineer": "Ingénieur Logiciel",
      company: "Tech Corp",
      "Developed software solutions using Java and Spring Boot":
        "Développement de solutions logicielles en utilisant Java et Spring Boot.",

      "Data Analyst": "Analyste de Données",
      company: "Analytics Firm",
      "Analyzed data to provide actionable insights using Python":
        "Analyse des données pour fournir des informations exploitables en utilisant Python.",

      // blogs

      "Championing Community Impact through Technology":
        "Promouvoir l'Impact Communautaire à Travers la Technologie",
      "At Hyphen Tech, our commitment extends beyond business growth and innovation. We are dedicated to harnessing the power of technology to create meaningful and lasting impacts in our communities. We are proud to highlight a significant milestone facilitated by one of our own. Dieudonné Abanabimana, our esteemed former COO, co-founder, and co-CTO, played a crucial role in the live broadcast of the 75th Jubilee of the Diocese of the Catholic Church of Ngozi. This momentous event celebrated the blessing of new priests as they embarked on their spiritual journeys. Supported by our talented interns, Dieudonné ensured that this important occasion reached a wider audience, allowing many to partake in the celebration virtually.This event is a testament to Hyphen Tech's mission to leverage technology for the greater good. We believe in the transformative power of tech solutions to enrich lives and strengthen communities.":
        "Chez Hyphen Tech, notre engagement va au-delà de la croissance de l'entreprise et de l'innovation. Nous nous consacrons à exploiter le pouvoir de la technologie pour créer des impacts significatifs et durables dans nos communautés. Nous sommes fiers de mettre en lumière une étape importante facilitée par l'un des nôtres. Dieudonné Abanabimana, notre estimé ancien COO, cofondateur et co-CTO, a joué un rôle clé dans la diffusion en direct du 75e Jubilé du Diocèse de l'Église Catholique de Ngozi. Cet événement marquant célébrait la bénédiction de nouveaux prêtres qui entament leur voyage spirituel. Avec le soutien de nos stagiaires talentueux, Dieudonné a veillé à ce que cette occasion importante atteigne un public plus large, permettant à beaucoup de participer virtuellement à la célébration. Cet événement témoigne de la mission de Hyphen Tech de tirer parti de la technologie pour le bien commun. Nous croyons au pouvoir transformateur des solutions technologiques pour enrichir les vies et renforcer les communautés.",

      "Hyphen Tech's Impact at the Africa Youth Leadership Forum":
        "Impact de Hyphen Tech sur le Forum des Jeunes Leaders d'Afrique",
      "On March 1, 2024, Hyphen Tech played a pivotal role in supporting the Africa Youth Leadership Forum at Ngozi University. Our team expertly managed and live-streamed the conference organized by the university's clubs, ensuring its success through dedication and technical proficiency. Following the conference, our founders, Elie Bubuya and Dieudonné Abanabimana, unveiled an innovative platform developed by Hyphen Tech. This platform, specifically designed for the students of Ngozi University, serves as a blog that facilitates access to and sharing of opportunities, scholarships, and discussions on leadership, entrepreneurship, and more.":
        "Le 1er mars 2024, Hyphen Tech a joué un rôle clé dans le soutien du Forum des Jeunes Leaders d'Afrique à l'Université de Ngozi. Notre équipe a géré et diffusé en direct avec compétence la conférence organisée par les clubs de l'université, assurant son succès grâce à un dévouement et une compétence technique. Après la conférence, nos fondateurs, Elie Bubuya et Dieudonné Abanabimana, ont présenté une plateforme innovante développée par Hyphen Tech. Cette plateforme, spécialement conçue pour les étudiants de l'Université de Ngozi, sert de blog qui facilite l'accès et le partage des opportunités, des bourses et des discussions sur le leadership, l'entrepreneuriat, et plus encore.",

      "Hyphen Tech Recognized Among Top Innovations in Burundi by IEEE":
        "Hyphen Tech Reconnu parmi les Meilleures Innovations au Burundi par IEEE",
      "On March 27th, Hyphen Tech had the incredible opportunity to participate in a prestigious technological competition organized by IEEE at the University of Lake Tanganyika. We proudly showcased two innovative projects that represent our dedication to pushing the boundaries of technology and creativity. 💡We are thrilled to announce that we have been selected among the top 5 best innovations in Burundi by the esteemed organization IEEE and have received certification. This recognition is a testament to our commitment to excellence and innovation. Stay tuned for more updates as we continue to innovate and strive for excellence! Thank you for your unwavering support.":
        "Le 27 mars, Hyphen Tech a eu l'incroyable opportunité de participer à une prestigieuse compétition technologique organisée par IEEE à l'Université de Tanganyika. Nous avons fièrement présenté deux projets innovants qui représentent notre engagement à repousser les limites de la technologie et de la créativité. 💡 Nous sommes ravis d'annoncer que nous avons été sélectionnés parmi les 5 meilleures innovations au Burundi par la prestigieuse organisation IEEE et avons reçu une certification! Cette reconnaissance témoigne de notre engagement envers l'excellence et l'innovation. Restez à l'écoute pour plus de mises à jour alors que nous continuons à innover et à nous efforcer d'atteindre l'excellence! Merci pour votre soutien indéfectible.",

      "Hyphen Tech CEO Elie Bubuya Advances AI Knowledge in Benin":
        "Le CEO de Hyphen Tech, Elie Bubuya, Avance la Connaissance en IA au Bénin",
      "Our CEO and co-CTO, Elie Bubuya, has embarked on a groundbreaking journey to Benin. 🚀 Over the course of a month, Elie immersed himself in the fundamentals of machine learning, artificial intelligence, and embedded systems with robotics. Elie was selected as one of only 120 individuals from across Africa to participate in the prestigious École d'Été sur Intelligence Artificielle (EEIA), organized in partnership with Foundation Vallet and Benin Excellence. This event is the largest educational gathering on Artificial Intelligence in Africa, with an astounding 9,013 applications! 🌍We are incredibly proud of Elie for passing this rigorous selection process and earning a place at such a distinguished event. His dedication and passion for advancing technology in East Africa are truly inspiring. Stay tuned for more updates as Elie explores these cutting-edge fields. We’ll be sharing more about his involvement in AI projects across East Africa soon!":
        "Notre CEO et co-CTO, Elie Bubuya, a entrepris un voyage révolutionnaire au Bénin. 🚀 Au cours d'un mois, Elie s'est immergé dans les fondamentaux de l'apprentissage automatique, de l'intelligence artificielle et des systèmes embarqués avec la robotique. Elie a été sélectionné parmi 120 individus de toute l'Afrique pour participer à la prestigieuse École d'Été sur Intelligence Artificielle (EEIA), organisée en partenariat avec la Fondation Vallet et Benin Excellence. Cet événement est la plus grande réunion éducative sur l'intelligence artificielle en Afrique, avec l'incroyable nombre de 9 013 candidatures! 🌍 Nous sommes incroyablement fiers d'Elie pour avoir franchi ce processus de sélection rigoureux et gagné une place dans un événement aussi distingué. Sa dévotion et sa passion pour faire avancer la technologie en Afrique de l'Est sont véritablement inspirantes. Restez à l'écoute pour plus de mises à jour alors qu'Elie explore ces domaines de pointe! Nous partagerons bientôt plus sur sa participation à des projets d'IA en Afrique de l'Est.",

      "Hyphen Tech Education: Empowering Future Innovators":
        "Éducation chez Hyphen Tech: Autonomiser les Innovateurs de Demain",
      "At Hyphen Tech, we believe that investing in technology and education is essential for building a prosperous society. As part of our commitment to this vision, Hyphen Tech Education, a non-profit initiative, aims to provide unfortunate children with the basics of informatics. Every year in August, during the vacation period, we partner with Ngozi University and the American Corner Ngozi to deliver this transformative program. Our mission is to share knowledge and inspire young minds, nurturing the future innovators of our society. By equipping children with foundational informatics skills, we aim to bridge the technology gap and create opportunities for them to thrive in an increasingly digital world. We recognize that technology remains largely unexploited in our country and across Africa. Through Hyphen Tech Education, we aspire to reach a larger audience in the future, extending our impact and fostering a culture of technological advancement. Join us in our journey to empower the next generation and build a brighter future through technology and education.":
        "Chez Hyphen Tech, nous croyons que l'investissement dans la technologie et l'éducation est essentiel pour construire une société prospère. Dans le cadre de notre engagement envers cette vision, Hyphen Tech Education, une initiative à but non lucratif, vise à fournir aux enfants défavorisés les concepts de base en informatique. Chaque année en août, pendant la période des vacances, nous nous associons à l'American Corner Ngozi pour enseigner à ces jeunes esprits curieux les notions de base de l'informatique et de la technologie, jetant ainsi les bases de leur avenir numérique. Notre engagement ne se limite pas aux périodes de vacances; nous offrons également un soutien technique continu aux étudiants dans leur parcours d'apprentissage tout au long de l'année scolaire, en partenariat avec l'Université de Ngozi et d'autres établissements éducatifs. Cette initiative illustre notre engagement à réduire la fracture numérique et à créer des opportunités égales pour tous, tout en favorisant une culture d'innovation technologique. Rejoignez-nous dans notre voyage pour autonomiser la prochaine génération et construire un avenir plus brillant grâce à la technologie et à l'éducation.",

      // showcases

      "Hyphen Tech developed a comprehensive business management software for Bismos Softwares, streamlining and optimizing their operations. Key features include user access control for secure, role-based system access, financial insights with daily, monthly, and yearly interest reports, employee management for timely salary payments, and an integrated credit system for managing employee credits. This solution demonstrates our commitment to providing tailored and impactful tech solutions, empowering clients to achieve their business goals efficiently.":
        "Hyphen Tech a développé un logiciel de gestion d'entreprise complet pour Bismos Softwares, rationalisant et optimisant leurs opérations. Les principales fonctionnalités comprennent le contrôle d'accès des utilisateurs pour un accès sécurisé au système basé sur les rôles, des informations financières avec des rapports d'intérêts quotidiens, mensuels et annuels, la gestion des employés pour des paiements de salaire en temps opportun et un système de crédit intégré pour la gestion des crédits des employés. Cette solution démontre notre engagement à fournir des solutions technologiques sur mesure et efficaces, permettant aux clients d'atteindre efficacement leurs objectifs commerciaux.",
      "Hyphen Tech collaborated with the University of Ngozi to enhance student engagement and event accessibility. We created a dedicated blog where students can exchange ideas, share opportunities, and access orientation resources. Additionally, we managed live broadcasts of key university events, including conferences organized by the Africa Youth Leadership Forum and the university itself. This initiative showcases our commitment to fostering academic collaboration and broadening access to important university activities.":
        "Hyphen Tech a collaboré avec l’Université de Ngozi pour améliorer l’engagement des étudiants et l’accessibilité aux événements. Nous avons créé un blog dédié où les étudiants peuvent échanger des idées, partager des opportunités et accéder à des ressources d’orientation. De plus, nous avons géré la diffusion en direct d’événements universitaires clés, notamment des conférences organisées par l’Africa Youth Leadership Forum et l’université elle-même. Cette initiative témoigne de notre engagement à favoriser la collaboration universitaire et à élargir l’accès aux activités universitaires importantes.",
      "Hyphen Tech partnered with American Corner Ngozi to elevate their digital presence and event promotion. We developed and implemented a comprehensive digital marketing strategy, utilized targeted campaigns to boost event attendance, and managed social media channels to strengthen community engagement. These efforts significantly increased visibility, attracted a larger audience, and enhanced community interaction, showcasing our expertise in delivering impactful digital marketing results.":
        "Hyphen Tech s'est associé à American Corner Ngozi pour renforcer sa présence numérique et la promotion de ses événements. Nous avons développé et mis en œuvre une stratégie complète de marketing numérique, utilisé des campagnes ciblées pour augmenter la participation aux événements et géré les réseaux de médias sociaux pour renforcer l'engagement communautaire. Ces efforts ont considérablement augmenté la visibilité, attiré un public plus large et amélioré l'interaction avec la communauté, mettant en valeur notre expertise dans la production de résultats de marketing numérique percutants.",
      "Hyphen Tech worked with Resolution Connect, a leading think tank in Burundi recognized among the top 10 by Africa Liberty. Our collaboration focused on digital marketing, mobilization, and design, enhancing their outreach and engagement efforts. This partnership underscores our commitment to empowering influential organizations with innovative and effective tech solutions.":
        "Hyphen Tech a travaillé avec Resolution Connect, un groupe de réflexion de premier plan au Burundi, reconnu parmi les 10 meilleurs par Africa Liberty. Notre collaboration s'est concentrée sur le marketing numérique, la mobilisation et la conception, améliorant ainsi leurs efforts de sensibilisation et d'engagement. Ce partenariat souligne notre engagement à fournir aux organisations influentes des solutions technologiques innovantes et efficaces.",
      "Hyphen Tech worked with Irathos Kingdom Medical Clinic to enhance their visibility and reach. We provided comprehensive social media management, along with professional video and photography services, to effectively showcase the clinic to a broader audience. Our efforts helped the clinic engage with the community and build a stronger online presence, demonstrating our expertise in digital marketing and media production.":
        "Hyphen Tech a travaillé avec la clinique médicale Irathos Kingdom pour améliorer sa visibilité et sa portée. Nous avons fourni une gestion complète des médias sociaux, ainsi que des services professionnels de vidéo et de photographie, pour présenter efficacement la clinique à un public plus large. Nos efforts ont aidé la clinique à interagir avec la communauté et à renforcer sa présence en ligne, démontrant ainsi notre expertise en marketing numérique et en production médiatique.",

      // showcase header

      "A real time software management":
        "Une logicielle de gestion  en temps réel",
      "A blog for student": "Un blog pour les étudiants",
      "Digital marketing": "Marketing numérique",
      "Event management and design": "Gestion et conception d'événements",
      "Social Media management": "Gestion des médias sociaux",

      //contact section
      "Connect with Us": "Connectez-vous avec nous",

      //experience and skills
      "At Hyphen Tech, we pride ourselves on delivering robust backend solutions designed with microservices and agile architecture. Our approach ensures scalable, flexible, and high-performing systems that grow with your business. We focus on creating reliable, secure, and maintainable infrastructure, enabling seamless integration and optimal performance. Whether it's a complex application or a straightforward service, our expertise lies in developing solutions that are tailored to your needs, powered by cutting-edge technology, and engineered for excellence. But we don't stop at the backend. Our team is equally dedicated to crafting stunning, user-friendly interfaces that offer an intuitive experience across all devices. We understand that the success of a product relies heavily on its ease of use and visual appeal. That's why we emphasize clean, responsive designs that engage users and drive satisfaction. In addition to our development services, we also offer comprehensive social media management for organizations and teams, helping you enhance your online presence, connect with your audience, and build a stronger digital footprint.":
        "Chez Hyphen Tech, nous sommes fiers de fournir des solutions backend robustes conçues avec des microservices et une architecture agile. Notre approche garantit des systèmes évolutifs, flexibles et performants qui croissent avec votre entreprise. Nous nous concentrons sur la création d'infrastructures fiables, sécurisées et maintenables, permettant une intégration fluide et une performance optimale. Que ce soit pour une application complexe ou un service simple, notre expertise réside dans le développement de solutions adaptées à vos besoins, propulsées par une technologie de pointe et conçues pour l'excellence.Mais nous ne nous arrêtons pas au backend. Notre équipe est également dédiée à la création d'interfaces époustouflantes et conviviales qui offrent une expérience intuitive sur tous les appareils. Nous comprenons que le succès d'un produit dépend fortement de sa facilité d'utilisation et de son attrait visuel. C'est pourquoi nous mettons l'accent sur des designs clairs et réactifs qui captivent les utilisateurs et favorisent leur satisfaction. En plus de nos services de développement, nous proposons également une gestion complète des réseaux sociaux pour les organisations et les équipes, vous aidant à améliorer votre présence en ligne, à vous connecter avec votre audience et à renforcer votre empreinte numérique.",
    },
  },
  es: {
    translation: {
      home: "Inicio",
      service: "Servicio",
      work: "Trabajo",
      skills: "Habilidades",
      team: "Equipo",
      blog: "Blog",
      testimonial: "testimonios",
      contact: "Contacto",

      //hero section
      "Empowering Success Through Technology":
        "Potenciando el Éxito a Través de la Tecnología",
      "Transforming lives and scaling businesses with technology":
        "Transformando vidas y escalando negocios con tecnología",
      "Get In Touch": "Contáctanos",
      " Visit AgriHyphen AI our ongoing project":
        "Visita AgriHyphen AI, nuestro proyecto en curso.",
      "Our services": "Nuestros servicios",
      // sections Header

      "The Showcase Study": "El estudio de escaparate",
      "Teamwork is the Key": "El trabajo en equipo es la clave",
      "The Team": "El equipo",
      "Skills & Experiences": "Competencias y experiencias",
      "Hello, Welcome to": "Hola, bienvenido a",
      "The hyphen Blog": "El blog de Hyphen Tech",
      "what we are Good at": "en qué somos buenos",
      "Our Portfolio": "Nuestro portafolio",

      // what we do better

      "Web Development": "Desarrollo Web",
      "We create stunning websites that showcase your activities to the world. Our websites are easy to use and look great on any device":
        "Creamos sitios web impresionantes que muestran tus actividades al mundo. Nuestros sitios web son fáciles de usar y se ven geniales en cualquier dispositivo.",

      "App Development": "Desarrollo de Aplicaciones",
      "We build apps for your business that work smoothly on both iPhones and Androids. Our apps have cool features to keep your users engaged":
        "Desarrollamos aplicaciones para tu negocio que funcionan sin problemas tanto en iPhones como en Androids. Nuestras aplicaciones tienen características interesantes para mantener a tus usuarios comprometidos.",

      "Microservices Architecture": "Arquitectura de Microservicios",
      "We help turn your business ideas into reality using smart and flexible technology. This means your business can grow and change easily":
        "Ayudamos a convertir tus ideas de negocio en realidad utilizando tecnología inteligente y flexible. Esto significa que tu negocio puede crecer y cambiar fácilmente.",

      "Deployment Services": "Servicios de Despliegue",
      "We make sure your website or app gets up and running smoothly. After it's launched, we keep an eye on it to make sure it works well all the time":
        "Nos aseguramos de que tu sitio web o aplicación funcione sin problemas. Después de su lanzamiento, lo supervisamos para asegurar que funcione bien en todo momento.",

      "UI, UX & Graphic Design": "Diseño UI, UX y Gráfico",
      "We create cool and unique visuals for your brand. Whether it's for your website or app, our designs will make you remarkable and stand out to the rest":
        "Creamos visuales únicos y geniales para tu marca. Ya sea para tu sitio web o aplicación, nuestros diseños te harán destacar y ser notable entre los demás.",

      "Social Media Management and Consultancy":
        "Gestión y Consultoría de Redes Sociales",
      "We help you build a strong online presence. We guide you on what to post and how to connect with your audience on social media.":
        "Te ayudamos a construir una presencia en línea sólida. Te guiamos sobre qué publicar y cómo conectarte con tu audiencia en las redes sociales.",

      "Web maitenancy": "Mantenimiento Web",
      "We specialize in web maintenance to keep your online presence strong. Our team goes beyond routine upkeep, adding new features and optimizing performance for lasting success":
        "Nos especializamos en el mantenimiento web para mantener tu presencia en línea fuerte. Nuestro equipo va más allá del mantenimiento rutinario, añadiendo nuevas funcionalidades y optimizando el rendimiento para un éxito duradero.",

      "Education Initiatives": "Iniciativas Educativas",
      "We love sharing knowledge! At Hyphen Tech Education, a non-profit initiative, We teach university students about computers and help high school students to get ready for their future studies and jobs.":
        "¡Nos encanta compartir conocimientos! En Hyphen Tech Education, una iniciativa sin fines de lucro, enseñamos a los estudiantes universitarios sobre informática y ayudamos a los estudiantes de secundaria a prepararse para sus futuros estudios y trabajos.",
      "Live streaming services": "Servicios de transmisión en directo",
      "We provide live streaming services for events and conferences to ensure that the events are accessible to a wider audience and to provide a platform for speakers to share their knowledge and experiences.":
        "Proporcionamos servicios de transmisión en directo para eventos y conferencias para garantizar que los eventos sean accesibles a un público más amplio y para proporcionar una plataforma para que los ponentes compartan sus conocimientos y experiencias.",

      // Our portofolio

      "AgriHyphen AI: Empowering Farmers with AI Technology":
        "AgriHyphen AI: Empoderando a los Agricultores con Tecnología de IA",
      "AgriHyphen AI is an innovative platform designed to empower farmers and agricultural cooperatives through the use of artificial intelligence. Our project focuses on leveraging computer vision technology to detect diseases in solanaceae crops, such as potatoes and tomatoes, with an impressive accuracy of 94%. By providing farmers with timely and accurate information about crop health, we aim to reduce waste and enhance productivity.":
        "AgriHyphen AI es una plataforma innovadora diseñada para empoderar a los agricultores y cooperativas agrícolas a través del uso de inteligencia artificial. Nuestro proyecto se centra en aprovechar la tecnología de visión por computadora para detectar enfermedades en cultivos de solanáceas, como papas y tomates, con una impresionante precisión del 94%. Al proporcionar a los agricultores información oportuna y precisa sobre la salud de los cultivos, buscamos reducir el desperdicio y mejorar la productividad.",

      "Hyphen tech blog, the blog for university students":
        "Blog de Hyphen Tech, el blog para estudiantes universitarios",
      "Provide Valuable And Insightful Content That Educates, Inspires, And Empowers Readers In Various Areas Of Interest":
        "Proporcionar contenido valioso e informativo que eduque, inspire y empodere a los lectores en diversas áreas de interés.",

      "Collabaration of Hyphen tech and American corner":
        "Colaboración entre Hyphen Tech y American Corner",
      "At Hyphen Tech, our collaboration with American Corner Ngozi began with a shared vision to advance technology and digital literacy in our community. This partnership has allowed us to create valuable content that educates and inspires":
        "En Hyphen Tech, nuestra colaboración con American Corner Ngozi comenzó con una visión compartida para avanzar en la tecnología y la alfabetización digital en nuestra comunidad. Esta asociación nos ha permitido crear contenido valioso que educa e inspira.",

      "Bismos impremerie softwares": "Software de Bismos Imprimerie",
      "we developed a comprehensive software management system for Bismos Imprimerie, revolutionizing their business operations. Our solution includes robust features for employee management, stock management, sales tracking, and invoicing.":
        "Desarrollamos un sistema de gestión de software integral para Bismos Imprimerie, revolucionando sus operaciones comerciales. Nuestra solución incluye características robustas para la gestión de empleados, gestión de inventario, seguimiento de ventas y facturación.",

      "Bismos print website": "Sitio web de Bismos Print",
      "We developed a  website for Bismos Print, designed to showcase their activities and services to a wider audience. This user-friendly platform highlights their extensive range of printing solutions, portfolio of past projects, and detailed service offerings.":
        "Desarrollamos un sitio web para Bismos Print, diseñado para mostrar sus actividades y servicios a una audiencia más amplia. Esta plataforma amigable con el usuario destaca su amplia gama de soluciones de impresión, portafolio de proyectos anteriores y ofertas de servicios detalladas.",

      "Hyphen Tech Education": "Hyphen Tech Education",
      "We love sharing knowledge! At Hyphen Tech Education, a non-profit initiative, We teach university students about computers and help high school students to get ready for their future studies and jobs.":
        "¡Nos encanta compartir conocimientos! En Hyphen Tech Education, una iniciativa sin fines de lucro, enseñamos a los estudiantes universitarios sobre informática y ayudamos a los estudiantes de secundaria a prepararse para sus futuros estudios y trabajos.",

      // Testimonials

      "Vice-Chancellor of Ngozi University":
        "Rector de la Universidad de Ngozi",
      "Hyphen Tech stands out for its commitment to providing concrete solutions to the daily problems of our community. Their innovative and practical approach shows real potential to transform our society.They have also created a blog for the students of Ngozi University, a crucial initiative that facilitates the exchange of ideas and opportunities. This work clearly demonstrates the credibility and vision of Hyphen Tech as one of the leaders in technological innovation in Burundi.":
        "Hyphen Tech se destaca por su compromiso de proporcionar soluciones concretas a los problemas diarios de nuestra comunidad. Su enfoque innovador y práctico muestra un verdadero potencial para transformar nuestra sociedad.También han creado un blog para los estudiantes de la Universidad de Ngozi, una iniciativa crucial que facilita el intercambio de ideas y oportunidades. Este trabajo demuestra claramente la credibilidad y la visión de Hyphen Tech como uno de los líderes en innovación tecnológica en Burundi.",

      "CEO and founder of Resolution Connect":
        "CEO y fundador de Resolution Connect",
      "Hyphen Tech has been instrumental in our startup's success. Their expertise in email marketing, event digital marketing, and logo crafting significantly boosted our brand's visibility. Their creativity and professionalism are unmatched. We highly recommend Hyphen Tech's exceptional services.":
        "Hyphen Tech ha sido fundamental en el éxito de nuestra startup. Su experiencia en marketing por correo electrónico, marketing digital de eventos y diseño de logotipos ha aumentado significativamente la visibilidad de nuestra marca. Su creatividad y profesionalismo son inigualables. Recomendamos encarecidamente los servicios excepcionales de Hyphen Tech.",

      "Coordinator of American corner Ngozi":
        "Coordinador de American Corner Ngozi",
      "Partnering with Hyphen Tech has revolutionized our digital marketing, live broadcasts of events, and space design at the American Corner Ngozi. Their dedication to promoting digital literacy among the underprivileged has made a significant impact. We highly recommend Hyphen Tech for their outstanding services.":
        "Asociarse con Hyphen Tech ha revolucionado nuestro marketing digital, las transmisiones en vivo de eventos y el diseño del espacio en American Corner Ngozi. Su dedicación a promover la alfabetización digital entre los desfavorecidos ha tenido un impacto significativo. Recomendamos encarecidamente a Hyphen Tech por sus servicios excepcionales.",
      "Trusted by": "Confiado por",

      // Team members

      "Co-founder & CEO of Hyphen Tech": "Co-fundador y CEO de Hyphen Tech",
      "Full Stack Developer": "Desarrollador Full Stack",
      "Meet Elie Bubuya, the driving force behind Hyphen Tech and a visionary leader. As the founder of Hyphen Tech, Elie exemplifies the spirit of a tech entrepreneur and a highly skilled full stack programmer. With a passion for innovation and a keen eye for emerging technologies, Elie brings a wealth of expertise to our team. His leadership sets the tone for our commitment to excellence and forward-thinking solutions, making him an integral part of our journey toward technological empowerment.":
        "Conoce a Elie Bubuya, la fuerza impulsora detrás de Hyphen Tech y un líder visionario. Como fundador de Hyphen Tech, Elie encarna el espíritu de un emprendedor tecnológico y un programador full stack altamente capacitado. Con una pasión por la innovación y un agudo ojo para las tecnologías emergentes, Elie aporta una gran cantidad de experiencia a nuestro equipo. Su liderazgo marca el tono de nuestro compromiso con la excelencia y las soluciones innovadoras, haciéndolo una parte integral de nuestro viaje hacia el empoderamiento tecnológico.",

      "Co-founder & COO of Hyphen Tech": "Co-fundador y COO de Hyphen Tech",
      "Full Stack Developer": "Desarrollador Full Stack",
      "Meet Dieudonne Abanabimana, the co-founder of Hyphen Tech. Passionate about programming, Dieudonne is a skilled full-stack developer who plays a key role in shaping our technological solutions. With a commitment to excellence, he brings invaluable expertise to our team, contributing to our mission of innovative and impactful solutions in the ever-evolving tech landscape":
        "Conoce a Dieudonné Abanabimana, co-fundador de Hyphen Tech. Apasionado por la programación, Dieudonné es un desarrollador full-stack experto que desempeña un papel clave en la configuración de nuestras soluciones tecnológicas. Con un compromiso con la excelencia, aporta una experiencia invaluable a nuestro equipo, contribuyendo a nuestra misión de soluciones innovadoras e impactantes en el paisaje tecnológico en constante evolución.",

      // Skills

      "Frontend Developer": "Desarrollador Frontend",
      company: "Tech Solutions",
      "Developed user interfaces using React and Redux":
        "Desarrolló interfaces de usuario utilizando React y Redux.",

      "UI/UX Designer": "Consultor de Diseño UX/UI",
      company: "Design Experts",
      "Worked on the UI/UX design of several web applications":
        "Trabajó en el diseño UX/UI de varias aplicaciones web.",

      Founder: "Fundador",
      company: "Startup Co.",
      "Started a successful tech startup focusing on innovative solutions":
        "Inició una startup tecnológica exitosa centrada en soluciones innovadoras.",
      "Lead Développeur": "Líder de Desarrollo",
      company: "Startup Innovadora",
      desc: "Dirección de un equipo de desarrolladores para la creación de soluciones innovadoras.",

      "Software Engineer": "Ingeniero de Software",
      company: "Tech Corp",
      "Developed software solutions using Java and Spring Boot":
        "Desarrolló soluciones de software utilizando Java y Spring Boot.",

      "Data Analyst": "Analista de Datos",
      company: "Firma de Análisis",
      "Analyzed data to provide actionable insights using Python":
        "Analizó datos para proporcionar información útil utilizando Python.",

      // blogs

      "Championing Community Impact through Technology":
        "Promover el Impacto Comunitario a través de la Tecnología",
      "At Hyphen Tech, our commitment extends beyond business growth and innovation. We are dedicated to harnessing the power of technology to create meaningful and lasting impacts in our communities. We are proud to highlight a significant milestone facilitated by one of our own. Dieudonné Abanabimana, our esteemed former COO, co-founder, and co-CTO, played a crucial role in the live broadcast of the 75th Jubilee of the Diocese of the Catholic Church of Ngozi. This momentous event celebrated the blessing of new priests as they embarked on their spiritual journeys. Supported by our talented interns, Dieudonné ensured that this important occasion reached a wider audience, allowing many to partake in the celebration virtually.This event is a testament to Hyphen Tech's mission to leverage technology for the greater good. We believe in the transformative power of tech solutions to enrich lives and strengthen communities.":
        "En Hyphen Tech, nuestro compromiso va más allá del crecimiento empresarial y la innovación. Estamos dedicados a aprovechar el poder de la tecnología para crear impactos significativos y duraderos en nuestras comunidades. Nos enorgullece destacar un hito importante facilitado por uno de los nuestros. Dieudonné Abanabimana, nuestro estimado ex COO, cofundador y co-CTO, desempeñó un papel crucial en la transmisión en vivo del 75º Jubileo del Diócesis de la Iglesia Católica de Ngozi. Este evento trascendental celebró la bendición de nuevos sacerdotes mientras emprendían sus caminos espirituales. Apoyado por nuestros talentosos pasantes, Dieudonné se aseguró de que esta importante ocasión llegara a una audiencia más amplia, permitiendo que muchos participaran en la celebración de manera virtual. Este evento es un testimonio de la misión de Hyphen Tech de aprovechar la tecnología para el bien común. Creemos en el poder transformador de las soluciones tecnológicas para enriquecer vidas y fortalecer comunidades.",

      "Hyphen Tech's Impact at the Africa Youth Leadership Forum":
        "Impacto de Hyphen Tech en el Foro de Liderazgo Juvenil de África",
      "On March 1, 2024, Hyphen Tech played a pivotal role in supporting the Africa Youth Leadership Forum at Ngozi University. Our team expertly managed and live-streamed the conference organized by the university's clubs, ensuring its success through dedication and technical proficiency. Following the conference, our founders, Elie Bubuya and Dieudonné Abanabimana, unveiled an innovative platform developed by Hyphen Tech. This platform, specifically designed for the students of Ngozi University, serves as a blog that facilitates access to and sharing of opportunities, scholarships, and discussions on leadership, entrepreneurship, and more.":
        "El 1 de marzo de 2024, Hyphen Tech desempeñó un papel crucial en el apoyo al Foro de Liderazgo Juvenil de África en la Universidad de Ngozi. Nuestro equipo gestionó y transmitió en vivo la conferencia organizada por los clubes de la universidad, asegurando su éxito a través de la dedicación y la competencia técnica. Tras la conferencia, nuestros fundadores, Elie Bubuya y Dieudonné Abanabimana, presentaron una plataforma innovadora desarrollada por Hyphen Tech. Esta plataforma, diseñada específicamente para los estudiantes de la Universidad de Ngozi, sirve como un blog que facilita el acceso y la compartición de oportunidades, becas y discusiones sobre liderazgo, emprendimiento y más.",

      "Hyphen Tech Recognized Among Top Innovations in Burundi by IEEE":
        "Hyphen Tech Reconocido Entre las Mejores Innovaciones en Burundi por IEEE",
      "On March 27th, Hyphen Tech had the incredible opportunity to participate in a prestigious technological competition organized by IEEE at the University of Lake Tanganyika. We proudly showcased two innovative projects that represent our dedication to pushing the boundaries of technology and creativity. 💡We are thrilled to announce that we have been selected among the top 5 best innovations in Burundi by the esteemed organization IEEE and have received certification. This recognition is a testament to our commitment to excellence and innovation. Stay tuned for more updates as we continue to innovate and strive for excellence! Thank you for your unwavering support.":
        "El 27 de marzo, Hyphen Tech tuvo la increíble oportunidad de participar en una prestigiosa competición tecnológica organizada por IEEE en la Universidad del Lago Tanganyika. Presentamos con orgullo dos proyectos innovadores que representan nuestro compromiso con la expansión de los límites de la tecnología y la creatividad. 💡 Nos complace anunciar que hemos sido seleccionados entre las 5 mejores innovaciones en Burundi por la organización IEEE y hemos recibido una certificación. Este reconocimiento es un testimonio de nuestro compromiso con la excelencia y la innovación. ¡Estén atentos para más actualizaciones mientras seguimos innovando y esforzándonos por la excelencia! Gracias por su apoyo incondicional.",

      "Hyphen Tech CEO Elie Bubuya Advances AI Knowledge in Benin":
        "El CEO de Hyphen Tech, Elie Bubuya, Avanza el Conocimiento en IA en Benín",
      "Our CEO and co-CTO, Elie Bubuya, has embarked on a groundbreaking journey to Benin. 🚀 Over the course of a month, Elie immersed himself in the fundamentals of machine learning, artificial intelligence, and embedded systems with robotics. Elie was selected as one of only 120 individuals from across Africa to participate in the prestigious École d'Été sur Intelligence Artificielle (EEIA), organized in partnership with Foundation Vallet and Benin Excellence. This event is the largest educational gathering on Artificial Intelligence in Africa, with an astounding 9,013 applications! 🌍We are incredibly proud of Elie for passing this rigorous selection process and earning a place at such a distinguished event. His dedication and passion for advancing technology in East Africa are truly inspiring. Stay tuned for more updates as Elie explores these cutting-edge fields. We’ll be sharing more about his involvement in AI projects across East Africa soon!":
        "Nuestro CEO y co-CTO, Elie Bubuya, ha emprendido un viaje revolucionario a Benín. 🚀 Durante un mes, Elie se sumergió en los fundamentos del aprendizaje automático, la inteligencia artificial y los sistemas embebidos con robótica. Elie fue seleccionado como uno de los 120 individuos de toda África para participar en la prestigiosa École d'Été sur Intelligence Artificielle (EEIA), organizada en asociación con la Fundación Vallet y Benin Excellence. Este evento es la mayor reunión educativa sobre Inteligencia Artificial en África, con un sorprendente número de 9.013 solicitudes. 🌍 Estamos increíblemente orgullosos de Elie por haber pasado este riguroso proceso de selección y haber ganado un lugar en un evento tan distinguido. Su dedicación y pasión por avanzar en la tecnología en África Oriental son verdaderamente inspiradoras. ¡Estén atentos para más actualizaciones mientras Elie explora estos campos de vanguardia! Pronto compartiremos más sobre su participación en proyectos de IA en África Oriental.",

      "Hyphen Tech Education: Empowering Future Innovators":
        "Hyphen Tech Educación: Empoderando a los Futuros Innovadores",
      "At Hyphen Tech, we believe that investing in technology and education is essential for building a prosperous society. As part of our commitment to this vision, Hyphen Tech Education, a non-profit initiative, aims to provide unfortunate children with the basics of informatics. Every year in August, during the vacation period, we partner with Ngozi University and the American Corner Ngozi to deliver this transformative program. Our mission is to share knowledge and inspire young minds, nurturing the future innovators of our society. By equipping children with foundational informatics skills, we aim to bridge the technology gap and create opportunities for them to thrive in an increasingly digital world. We recognize that technology remains largely unexploited in our country and across Africa. Through Hyphen Tech Education, we aspire to reach a larger audience in the future, extending our impact and fostering a culture of technological advancement. Join us in our journey to empower the next generation and build a brighter future through technology and education.":
        "En Hyphen Tech, creemos que invertir en tecnología y educación es esencial para construir una sociedad próspera. Como parte de nuestro compromiso con esta visión, Hyphen Tech Educación, una iniciativa sin fines de lucro, tiene como objetivo proporcionar a los niños desfavorecidos los conceptos básicos de informática. Cada año en agosto, durante el período de vacaciones, nos asociamos con la Universidad de Ngozi y el American Corner Ngozi para ofrecer este programa transformador. Nuestra misión es compartir conocimientos e inspirar a las mentes jóvenes, fomentando a los futuros innovadores de nuestra sociedad. Al proporcionar a los niños habilidades informáticas fundamentales, buscamos cerrar la brecha tecnológica y crear oportunidades para que prosperen en un mundo cada vez más digital. Reconocemos que la tecnología sigue siendo en gran medida no explotada en nuestro país y en toda África. A través de Hyphen Tech Educación, aspiramos a llegar a una audiencia más amplia en el futuro, extendiendo nuestro impacto y fomentando una cultura de avance tecnológico. Únete a nosotros en nuestro viaje para empoderar a la próxima generación y construir un futuro más brillante a través de la tecnología y la educación.",

      // showcase

      "Hyphen Tech developed a comprehensive business management software for Bismos Softwares, streamlining and optimizing their operations. Key features include user access control for secure, role-based system access, financial insights with daily, monthly, and yearly interest reports, employee management for timely salary payments, and an integrated credit system for managing employee credits. This solution demonstrates our commitment to providing tailored and impactful tech solutions, empowering clients to achieve their business goals efficiently.":
        "Hyphen Tech desarrolló un software de gestión empresarial integral para Bismos Softwares, racionalizando y optimizando sus operaciones. Las principales características incluyen control de acceso de usuarios para un acceso seguro basado en roles, información financiera con informes de intereses diarios, mensuales y anuales, gestión de empleados para pagos de salario puntuales y un sistema de crédito integrado para la gestión de créditos de empleados. Esta solución demuestra nuestro compromiso con la provisión de soluciones tecnológicas personalizadas e impactantes, permitiendo a los clientes alcanzar sus objetivos comerciales de manera eficiente.",

      "Hyphen Tech collaborated with the University of Ngozi to enhance student engagement and event accessibility. We created a dedicated blog where students can exchange ideas, share opportunities, and access orientation resources. Additionally, we managed live broadcasts of key university events, including conferences organized by the Africa Youth Leadership Forum and the university itself. This initiative showcases our commitment to fostering academic collaboration and broadening access to important university activities.":
        "Hyphen Tech colaboró con la Universidad de Ngozi para mejorar el compromiso estudiantil y la accesibilidad a eventos. Creamos un blog dedicado donde los estudiantes pueden intercambiar ideas, compartir oportunidades y acceder a recursos de orientación. Además, gestionamos transmisiones en vivo de eventos universitarios clave, incluyendo conferencias organizadas por el Africa Youth Leadership Forum y la propia universidad. Esta iniciativa muestra nuestro compromiso con la colaboración académica y la ampliación del acceso a actividades universitarias importantes.",

      "Hyphen Tech partnered with American Corner Ngozi to elevate their digital presence and event promotion. We developed and implemented a comprehensive digital marketing strategy, utilized targeted campaigns to boost event attendance, and managed social media channels to strengthen community engagement. These efforts significantly increased visibility, attracted a larger audience, and enhanced community interaction, showcasing our expertise in delivering impactful digital marketing results.":
        "Hyphen Tech se asoció con American Corner Ngozi para elevar su presencia digital y la promoción de eventos. Desarrollamos e implementamos una estrategia integral de marketing digital, utilizamos campañas dirigidas para aumentar la asistencia a eventos y gestionamos canales de redes sociales para fortalecer el compromiso comunitario. Estos esfuerzos aumentaron significativamente la visibilidad, atrajeron a una audiencia mayor y mejoraron la interacción con la comunidad, mostrando nuestra experiencia en la entrega de resultados impactantes de marketing digital.",

      "Hyphen Tech worked with Resolution Connect, a leading think tank in Burundi recognized among the top 10 by Africa Liberty. Our collaboration focused on digital marketing, mobilization, and design, enhancing their outreach and engagement efforts. This partnership underscores our commitment to empowering influential organizations with innovative and effective tech solutions.":
        "Hyphen Tech trabajó con Resolution Connect, un grupo de reflexión líder en Burundi, reconocido entre los 10 mejores por Africa Liberty. Nuestra colaboración se centró en marketing digital, movilización y diseño, mejorando sus esfuerzos de alcance y compromiso. Esta asociación subraya nuestro compromiso con el fortalecimiento de organizaciones influyentes con soluciones tecnológicas innovadoras y efectivas.",

      "Hyphen Tech worked with Irathos Kingdom Medical Clinic to enhance their visibility and reach. We provided comprehensive social media management, along with professional video and photography services, to effectively showcase the clinic to a broader audience. Our efforts helped the clinic engage with the community and build a stronger online presence, demonstrating our expertise in digital marketing and media production.":
        "Hyphen Tech trabajó con la clínica médica Irathos Kingdom para mejorar su visibilidad y alcance. Proporcionamos una gestión integral de redes sociales, junto con servicios profesionales de video y fotografía, para presentar eficazmente la clínica a una audiencia más amplia. Nuestros esfuerzos ayudaron a la clínica a interactuar con la comunidad y construir una presencia en línea más sólida, demostrando nuestra experiencia en marketing digital y producción de medios.",

      // showcase header

      "A real time software management":
        "Una software de gestión en tiempo real",
      "A blog for student": "Un blog para estudiantes",
      "Digital marketing": "Marketing digital",
      "Event management and design": "Gestión y diseño de eventos",
      "Social Media management": "Gestión de redes sociales",

      //contact section
      "Connect with Us": "Conéctate con nosotros",

      //experience and skills section
      "At Hyphen Tech, we pride ourselves on delivering robust backend solutions designed with microservices and agile architecture. Our approach ensures scalable, flexible, and high-performing systems that grow with your business. We focus on creating reliable, secure, and maintainable infrastructure, enabling seamless integration and optimal performance. Whether it's a complex application or a straightforward service, our expertise lies in developing solutions that are tailored to your needs, powered by cutting-edge technology, and engineered for excellence. But we don't stop at the backend. Our team is equally dedicated to crafting stunning, user-friendly interfaces that offer an intuitive experience across all devices. We understand that the success of a product relies heavily on its ease of use and visual appeal. That's why we emphasize clean, responsive designs that engage users and drive satisfaction. In addition to our development services, we also offer comprehensive social media management for organizations and teams, helping you enhance your online presence, connect with your audience, and build a stronger digital footprint.":
        "En Hyphen Tech, nos enorgullecemos de ofrecer soluciones de backend robustas diseñadas con microservicios y arquitectura ágil. Nuestro enfoque garantiza sistemas escalables, flexibles y de alto rendimiento que crecen con tu negocio. Nos enfocamos en crear infraestructuras fiables, seguras y mantenibles, que permiten una integración sin problemas y un rendimiento óptimo. Ya sea una aplicación compleja o un servicio sencillo, nuestra experiencia radica en desarrollar soluciones adaptadas a tus necesidades, impulsadas por tecnología de vanguardia y diseñadas para la excelencia.Pero no nos detenemos en el backend. Nuestro equipo también está dedicado a crear interfaces impresionantes y amigables que ofrecen una experiencia intuitiva en todos los dispositivos. Entendemos que el éxito de un producto depende en gran medida de su facilidad de uso y atractivo visual. Por eso, enfatizamos diseños limpios y receptivos que capturan la atención de los usuarios y fomentan la satisfacción. Además de nuestros servicios de desarrollo, también ofrecemos una gestión integral de redes sociales para organizaciones y equipos, ayudándote a mejorar tu presencia en línea, conectar con tu audiencia y construir una huella digital más sólida.",
    },
  },
};
